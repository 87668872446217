
import React from 'react'
import moment from 'moment-timezone'
import { put, call, takeLatest, all, select } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { isNull } from 'lodash'
import { SuccessToast, ErrorToast } from 'Themes/ScufStyledComponents'
import get from 'lodash/get'
import { LicenseInstallerTypes, LicenseInstallerActions } from './actions'
import { LicenseInstallerApi } from 'Services'
import { SessionExpiryActions } from 'Features/SessionExpiry/store'
import config from 'Config/AppConfig'
import {
  INSTALL_NOW,
  SCHEDULE,
  REQUEST_PRIORITY_IMMEDIATE,
  REQUEST_PRIORITY_OPTIONAL,
  ASSET_NAME,
  ASSET_TYPE,
  DEVICE_TYPE_MOBILE_COMPUTER,
  SCHEDULED_VERSION,
  ASSET_CATEGORY,
  DEVICE_VERSION,
  SITE_FILTER_TYPE,
  ORG_FILTER_TYPE,
  DEFAULT_PROFILE_ID
} from '../licenseInstaller.constants'

export function * getDevices ({ siteId , hasChildSites , filterType , pageNumber , currentData=[], searchString}) {
  if(config.nonSinaps)
  {
      yield put(LicenseInstallerActions.updateDevices([], 0))
  }
  else
  {
    const filterData = { 'siteId': { $in: [`${siteId}`] }, 'type': 'mobilecomputer' };
    if (hasChildSites || filterType == ORG_FILTER_TYPE) {
      filterData['includeChildSites'] = 'true';
    }
    if (searchString) {
      filterData['$freeform'] = searchString.split();
    }
    const response = yield call(LicenseInstallerApi.getDevices, { filterData, filterType, pageNumber })
    
    if (response.ok && response.data) {
      const devices = response.data.data.map(device =>
        prepareDevice(device)
      )          
      const { deviceFilterType, createBundleFilter: { includeChildSites }, devicesList } = yield select(getStoreData)

      if (deviceFilterType != filterType || (filterType == SITE_FILTER_TYPE && (includeChildSites && !hasChildSites || !includeChildSites && hasChildSites))) {
        yield put(LicenseInstallerActions.updateDevices([], 0))
      }
      
      yield all([
        put(LicenseInstallerActions.updateDevices([...currentData, ...devices], response.data.total)),
        put(LicenseInstallerActions.updateDeviceFilterType(filterType)),
        put(LicenseInstallerActions.updateSelectedSiteId(siteId)),
        put(LicenseInstallerActions.updateCreateBundleFilter(filterData))
      ]) 
      
    } else {
      if (response.status === 401) {
        yield all([
          put(SessionExpiryActions.sessionExpiry())
        ])
      }
      yield all([
        put(LicenseInstallerActions.updateDevicesFailure())
      ])
    }
  }
}
function getStoreData({ licenseInstaller }) {
  const { deviceFilterType, createBundleFilter, devicesList } = licenseInstaller

  return { deviceFilterType, createBundleFilter, devicesList }
}

export function* getSites({ siteId }) {

  const response = yield call(LicenseInstallerApi.getSites, { siteId })
  if (response.ok && response.data) {

    let filteredSites = response.data;
    if (response.data[0]?.organizationalUnitIdentifier?.organizationalUnitGuid !== siteId) {
      filteredSites = response.data.filter((d) => d?.organizationalUnitIdentifier?.resourceId.includes(siteId))
    }
    const sites = filteredSites.map(s => ({
      value: { id: s.organizationalUnitIdentifier.organizationalUnitGuid, name: s.organizationalUnitHierarchy },
      text: s.organizationalUnitHierarchy
    }))
    yield put(LicenseInstallerActions.updateSites(sites))
  } else {
    if (response.status === 401) {
      yield all([
        put(SessionExpiryActions.sessionExpiry())
      ])
    }
    yield all([
      put(LicenseInstallerActions.updateSitesFailure())
    ])
  }
}

export function * saveBundle (api) {
  try {
    const saveBundlePayload = yield select(saveBundleSelector)
    const response = yield call(api.insertBundle, saveBundlePayload)
    if (response.ok && response.data) {
      if (response.data.statusCode === '200') {
        if(config.nonSinaps)
        {
          // Toast Bundle Saved
          if (saveBundlePayload.id === '' || isNull(saveBundlePayload.id)) {
            toast(<SuccessToast message='License Bundle Created Successfully' />)
          } else {
            toast(<SuccessToast message='License Bundle Edited Successfully' />)
          }
          yield put(LicenseInstallerActions.saveBundleComplete(1))
        }
        else
        {
          // Validate for SInaps and Non-Sinaps
          // invokeScheduleBundle(api, response, saveBundlePayload)
          const scheduleBundlePayload = yield select(scheduleBundleSelector, response.data.assetInfo.caidcAssetId, saveBundlePayload.bundleName, response.data.devicesInfo)
          if (scheduleBundlePayload) {
            const scheduleResponse = yield call(api.scheduleBundle, scheduleBundlePayload)
            if (scheduleResponse.ok && scheduleResponse.data) {
              // Toast Bundle saved and scheduled
              toast(<SuccessToast message='License Bundle Scheduled Successfully' />)
              yield put(LicenseInstallerActions.saveBundleComplete(1))
            } else {
              // Toast Bundle saved but failed to schedule
              toast(<ErrorToast message='License Bundle Scheduling Failed' />)
              yield put(LicenseInstallerActions.saveBundleComplete(-1))
            }
          } else {
            // Toast Bundle Saved
            if (saveBundlePayload.id === '' || isNull(saveBundlePayload.id)) {
              toast(<SuccessToast message='License Bundle Created Successfully' />)
            } else {
              toast(<SuccessToast message='License Bundle Edited Successfully' />)
            }
            yield put(LicenseInstallerActions.saveBundleComplete(1))
          }
        }
      } else {
        // Failure toast
        const msg = getErrorMessage(response.data.statusCode)
        toast(<ErrorToast message={msg} />)
        yield put(LicenseInstallerActions.saveBundleComplete(-1))
      }
    } else {
      if (response.status === 401) {
        yield all([
          put(SessionExpiryActions.sessionExpiry())
        ])
      } else { toast(<ErrorToast message='License Bundle Creation Failed' />) }
      yield put(LicenseInstallerActions.saveBundleComplete(-1))
    }
  } catch (error) {
    toast(<ErrorToast message='Error Creating Bundle' />)
    yield put(LicenseInstallerActions.saveBundleComplete(-1))
  }
}

function * invokeScheduleBundle (api, response, saveBundlePayload) {
  const scheduleBundlePayload = yield select(scheduleBundleSelector, response.data.assetInfo.caidcAssetId, saveBundlePayload.bundleName)
  if (scheduleBundlePayload) {
    const scheduleResponse = yield call(api.scheduleBundle, scheduleBundlePayload)
    if (scheduleResponse.ok && scheduleResponse.data) {
      // Toast Bundle saved and scheduled
      toast(<SuccessToast message='License Bundle Scheduled Successfully' />)
      yield put(LicenseInstallerActions.saveBundleComplete(1))
    } else {
      // Toast Bundle saved but failed to schedule
      toast(<ErrorToast message='License Bundle Scheduling Failed' />)
      yield put(LicenseInstallerActions.saveBundleComplete(-1))
    }
  } else {
    // Toast Bundle Saved
    if (saveBundlePayload.id === '' || isNull(saveBundlePayload.id)) {
      toast(<SuccessToast message='License Bundle Created Successfully' />)
    } else {
      toast(<SuccessToast message='License Bundle Edited Successfully' />)
    }
    yield put(LicenseInstallerActions.saveBundleComplete(1))
  }
}

function getErrorMessage (data) {
  // Failure toast
  let msg = 'License Bundle Creation Failed'
  switch (data) {
    case ('104'):
      msg = 'Invalid Devices in request'
      break
    case ('105'):
      msg = 'Devices not found'
      break
    case ('106'):
      msg = 'Device type incompatible'
      break
    case ('107'):
      msg = 'License Bundle Information not found'
      break
    case ('108'):
      // Devices existing in the bundle missing in Edit Bundle request
      msg = 'Existing device in bundle missing from request'
      break
    case ('109'):
      msg = 'License not found'
      break
    case ('110'):
      msg = 'License returned'
      break
    case ('111'):
      msg = 'License count not enough'
      break
    case ('112'):
      msg = 'License not available to return'
      break
    case ('500'):
    default:
      msg = 'License Bundle Creation Failed'
      break
  }
  return msg
}

function saveBundleSelector ({ licenseInstaller }) {
  const deviceType = DEVICE_TYPE_MOBILE_COMPUTER
  const bundleName = licenseInstaller.getIn(['bundleName'], '')
  const activationIds = licenseInstaller.getIn(['selectedSoftwares'], []).map(software => software.activationId)
  const licenseIds = licenseInstaller.getIn(['selectedSoftwares'], []).map(software => software.licenseId)
  const selectedDevices = licenseInstaller.getIn(['selectedDevicesList'], []).map((device) => ({ Model: ((device.model == null) ? device.displayModel : device.model).toUpperCase(), SerialNumber: get(device, 'serialNumber', '') }))
  const { siteId } = licenseInstaller.getIn(['selectedSoftwares'], [])[0]
  const siteName = licenseInstaller.getIn(['selectedDevicesList'], [])[0]?.siteHierarchy || licenseInstaller.getIn(['selectedSiteId'], '')
  const id = licenseInstaller.getIn(['id'], null)
  const deviceFilterType = licenseInstaller.getIn(['deviceFilterType'], '')
  const filter = JSON.stringify(licenseInstaller.getIn(['createBundleFilter'],{}))
  const profileId = DEFAULT_PROFILE_ID
  const pageSize = licenseInstaller.getIn(['deviceFilterType'], 1) == 1 ? licenseInstaller.getIn(['totalDevicesCount'], 0) : selectedDevices.length
  const pageNumber = 1 // Added this value for handling the error in the bysite bundle creation
  return {
    bundleName,
    activationIds,
    licenseIds,
    SelectedDevices: selectedDevices,
    deviceType,
    siteId,
    siteName,
    id,
    deviceFilterType,
    filter,
    profileId,
    pageSize,
    pageNumber
  }
}

function scheduleBundleSelector ({ licenseInstaller }, responseAssetId, bundleName, devices) {
  const scheduleType = licenseInstaller.getIn(['scheduleType'], 2)
  const scheduleTimeZone = licenseInstaller.getIn(['scheduleTimezone'], null)
  const scheduleFromDatetime = licenseInstaller.getIn(['scheduleFromDatetime'], null)
  const scheduleToDatetime = licenseInstaller.getIn(['scheduleToDatetime'], null)
  const selectedDevicesList = licenseInstaller.getIn(['selectedDevicesList'], [])
  const deviceFilterType = licenseInstaller.getIn(['deviceFilterType'], 0)
  let scheduleBundlePayload = null
  if (scheduleType === INSTALL_NOW) {
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    scheduleBundlePayload = {
      requestPriority: REQUEST_PRIORITY_IMMEDIATE,
      scheduleTime: scheduleFromDatetime,
      scheduleTimeZone,
      retryUntilTime: scheduleToDatetime
    }
  } else if (scheduleType === SCHEDULE) {
    scheduleBundlePayload = {
      requestPriority: REQUEST_PRIORITY_OPTIONAL,
      scheduleTime: scheduleFromDatetime,
      scheduleTimeZone,
      retryUntilTime: scheduleToDatetime
    }
  }

  if (scheduleBundlePayload) {
    scheduleBundlePayload = {
      ...scheduleBundlePayload,
      CommandParameters: {
        AssetId: responseAssetId,
        AssetName: ASSET_NAME + bundleName
      },
      deviceList: scheduleDeviceListPayload(deviceFilterType == SITE_FILTER_TYPE ? devices : selectedDevicesList, responseAssetId, bundleName)
    }
  }

  return scheduleBundlePayload
}

function scheduleDeviceListPayload (devices, responseAssetId, bundleName) {
  return devices.map(device => (
    {
      CommandParameters: {
        DeviceType: DEVICE_TYPE_MOBILE_COMPUTER,
        FileName: ASSET_NAME + bundleName
      },
      AssetType: ASSET_TYPE,
      DeviceSerialNumber: device.serialNumber,
      OperationType: '',
      ScheduledVersion: SCHEDULED_VERSION,
      SiteId: device.siteId,
      SystemGuid: device.systemGuid,
      assetCategory: ASSET_CATEGORY,
      caidcAssetId: responseAssetId,
      displayName: ASSET_NAME + bundleName,
      siteName: device.siteHierarchy,
      currentVersion: DEVICE_VERSION
    }
  ))
}

function * updateLicenseInstaller ({ selectedBundle, mode }) {
  let licenseInstaller
  try {
    const { id, bundleName, deviceLicensesList, siteId } = selectedBundle[0]
    const selectedSoftwares = deviceLicensesList.map((license) => {
      return {
        activationId: license.activationId,
        featureName: license.featureName,
        expiresOn: license.maintenanceExpiration,
        expirationDate: license.expirationDate,
        availableCount: license.availableCount,
        siteId,
        licenseId: license.licenseId
      }
    })
    const deviceSoftwareObj = deviceLicensesList[0]
    const bundleDevices = deviceSoftwareObj.deviceStatusInfo.map(({ serialNumber }) => serialNumber)
    licenseInstaller = {
      id,
      bundleName,
      selectedSoftwares,
      bundleDevices
    }
    let selectedDevicesList = []
    if (!config.nonSinaps) {
      const searchTerms = [...bundleDevices]
      const filterData = { 'siteId': { $in: [`${siteId}`] }, 'type': 'mobilecomputer', $freeform: searchTerms };
      if (mode === 'edit') {
        filterData['includeChildSites'] = 'true';
      }
      const response = yield call(LicenseInstallerApi.getDevices, { filterData })
      if (response.ok && response.data) {
        const devices = response.data.data
        if (bundleDevices.length !== response.data.total) {
          // for showing alert to user when previously selected device is deleted
        }
        devices.forEach(device => {
          selectedDevicesList = [...selectedDevicesList, prepareDevice(device)]
        })
      }
    }
    else {
      const selectedDev = deviceSoftwareObj.deviceStatusInfo.map(device => (
        {
          displayModel: device.model,
          type: 'mobilecomputer',
          serialNumber: device.serialNumber.substring(device.model.length, device.serialNumber.length),
          alias: device.serialNumber,
          name: device.serialNumber,
        }
      ))
      selectedDevicesList = selectedDev
      yield all([
        put(LicenseInstallerActions.updateDevices(selectedDevicesList)),
        put(LicenseInstallerActions.updateSelectedDevices(selectedDevicesList))
      ])

    }
    licenseInstaller.selectedDevicesList = selectedDevicesList
    yield put(LicenseInstallerActions.updateLicenseInstallerState(licenseInstaller))
  } catch (error) {
    // Error Toast Message
    // yield put(LicenseInstallerActions.reset)
  }
}

function prepareDevice (device) {
  const { id, name, type, serialNumber, model, displayModel, siteId, siteHierarchy } = device
  return {
    id,
    name,
    type,
    serialNumber,
    model,
    displayModel,
    siteId,
    siteHierarchy,
    systemGuid: device.properties ? device.properties.SystemGuid : null,
    status: device.properties ? device.properties.Status : null
  }
}

export function * licenseInstallerSagas () {
  yield all([
    takeLatest(LicenseInstallerTypes.GET_DEVICES, getDevices),
    takeLatest(LicenseInstallerTypes.SAVE_BUNDLE, saveBundle, LicenseInstallerApi),
    takeLatest(LicenseInstallerTypes.UPDATE_LICENSE_INSTALLER, updateLicenseInstaller),
    takeLatest(LicenseInstallerTypes.GET_SITES, getSites)
  ])
}
