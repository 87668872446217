import styled from 'styled-components/macro'
import { Radio, Select, DatePicker, Input } from '@scuf/common'

export const StyledScheduleContainer = styled.div`
    width:100%;
    display:flex;
    flex-direction: column;
    over-flow: visible !important;
`
export const StyledDiv = styled.div`
    font-weight: 500 !important;    
    margin-bottom: 0.812rem;
    text-align: justify !important;
    color: #707070 !important;
`
export const StyledSelectContainer = styled.div`
    display: flex;
`
export const StyledRadio = styled(Radio)`
    & .ui.radio.checkbox input ~ label{
        font-weight: 500;
    }
`
export const StyledTimeZoneSelect = styled(Select)`
    width: 50% !important;
    margin: 1rem 0;
    & i.icon.chevron{
        font-size: 1rem !important;
        font-weight: 600 !important;
    }
`
export const StyledTimeSelect = styled(Select)`
    //width: 50% !important;
    //margin: 1rem 0;
    margin-right: 2rem;
    & i.icon.chevron{
        font-size: 1rem !important;
        font-weight: 600 !important;
    }
`
export const StyledInput = styled(Input)`
    //width: 50% !important;
    //margin: 1rem 0;
    margin-right: 2rem;
    & i.icon.chevron{
        font-size: 1rem !important;
        font-weight: 600 !important;
    }
`
export const StyledDatePicker = styled(DatePicker)`
    //width: 28%;
    bottom: none !important;
    margin-right: 2rem !important;
    & .overlay-position-wrap{
        z-index: 1 !important;
    }
`
export const StyledInstalLaterContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex: 1;
    align-content: center;
    justify-content: center;
`
export const StyledItemContainer = styled.div`
    display: flex;
    align-items: center;
    justify-items: center;
    flex-direction: column;
    align-self: center;
    justify-self: center;
    .h-icon.common{
        color: #E0E0E0 !important;
        font-weight:600 !important;
    }
`
export const OnboardTitle = styled.div`
  font-size: 2em;
  font-weight: 500;
  color: #404040;
  margin: 1rem;
`

export const OnboardSubtitle = styled.div`
  font-size: 1.25em;
  font-weight: 500;
  color: #606060;
  width: 65%;
  text-align: center;
`
export const ScheduleDateTime = styled.div`
justify-content: space-between;
display: flex;
flex-direction: row;
width: 100%;
`
export const ScheduleDate = styled.div`
display:block;
`

export const ScheduleDateTitle = styled.div`
font-size: 1rem;
font-weight: bolder;
color:#303030;
`
export const ScheduleDateRangeBox = styled.div`
display: flex;
flex-direction: row;
width: 100%;
margin-top: 1rem;
`
export const ScheduleSeparator = styled.div`
border-left: 1px solid rgb(216, 216, 216);
margin-right: 3rem !important;
`
export const InputWrapper = styled.div`
  padding: 0 ;
  position:relative;
  text-overflow: ellipsis;
  &  .ui.single-input .input-box {
    height: 2.375rem ! important;
  }
  & .ui.scuf-dropdown-wrapper>.ui.selection.dropdown.fluid, html:not(.honeywell-dark-theme) .ui.scuf-dropdown-wrapper>.ui.multiple.selection.dropdown.fluid, html:not(.honeywell-dark-theme) .ui.scuf-dropdown-wrapper>.ui.dropdown.disabled.fluid {
    justify-content: space-between;
  }
  & .ui.scuf-dropdown-wrapper>.ui.selection.dropdown i.icon.chevron:before, html:not(.honeywell-dark-theme) .ui.scuf-dropdown-wrapper>.ui.multiple.selection.dropdown i.icon.chevron:before, html:not(.honeywell-dark-theme) .ui.scuf-dropdown-wrapper>.ui.dropdown.disabled i.icon.chevron:before {
    padding-right: .5rem;
  }
  & .ui.dropdown>.text {
    max-width: 16rem !important;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & .ui.scuf-dropdown-wrapper.disabled .ui.disabled.dropdown i.icon {
    color: #f7f7f7 !important;
  }
  & .visible {
    max-height: 125px !important;
  }
`
