import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  reset: null,
  loading: null,
  devicesLoading: null,
  getDevices: ['siteId','hasChildSites','filterType' , 'pageNumber' , 'currentData', 'searchString'],
  updateDevices: ['devices','totalCount'],
  updateDevicesFailure: null,
  updateSelectedSoftwares: ['selectedSoftwares'],
  updateBundleName: ['bundleName'],
  updateSelectedDevices: ['selectedDevices'],
  updateScheduleType: ['scheduleType'],
  updateScheduleZoneAndDatetime: ['timezone', 'datetime', 'scheduleFromDate', 'scheduleFromTime'],
  updateScheduleZoneAndToDatetime: ['timezone', 'datetime', 'scheduleToDate', 'scheduleToTime'],
  saveBundle: null,
  back: null,
  updateLicenseInstaller: ['selectedBundle', 'mode'],
  updateLicenseInstallerState: ['selectedBundle'],
  saveBundleComplete: ['toastType'],
  getSites: ['siteId'],
  updateSites:['sitesList'],
  updateSitesFailure: null,
  deviceFilterType: '',
  updateDeviceFilterType: ['deviceFilterType'],
  selectedSiteId: '',
  updateSelectedSiteId:['selectedSiteId'],
  createBundleFilter: {},
  updateCreateBundleFilter: ['createBundleFilter']
}, {
  prefix: '@LICENSEINSTALLER/'
})

export const LicenseInstallerTypes = Types
export const LicenseInstallerActions = Creators
