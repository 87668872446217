import Immutable from 'seamless-immutable'
import { createReducer } from 'reduxsauce'
import { LicenseInstallerTypes } from './actions'
import { isNullOrUndefined } from 'util'

export const INITIAL_STATE = Immutable({
  loading: false,
  id: null,
  selectedSoftwares: [],
  loadingDevices: false,
  devicesList: [],
  bundleName: '',
  selectedDevicesList: [],
  scheduleType: 3,
  scheduleTimezone: null,
  scheduleFromDatetime: null,
  scheduleFromDate: null,
  scheduleFromTime: null,
  scheduleToDate: null,
  scheduleToTime: null,
  scheduleToDatetime: null,
  bundleDevices: [],
  showToast: 0,  
  sitesList:[],
  message: '',
  deviceFilterType: 0,
  totalDevicesCount: 0,
  selectedSiteId: '',
  createBundleFilter:{}
})
const loading = state => state.merge({ loading: true, showToast: 0, message: '' })
const back = state => state.merge({ loading: false, showToast: 0, message: '' })
const saveBundleComplete = (state, { toastType }) => state.merge({ showToast: toastType, loading: false })
const selectedSoftwares = (state, { selectedSoftwares }) => state.merge({ selectedSoftwares })
const loadingDevices = state => state.merge({ loading: true })
const updateDevicesList = (state, { devices , totalCount}) => state.merge({ devicesList: devices, totalDevicesCount: totalCount ,loading: false })
const updateDevicesFailure = state => state.merge({ loading: false, devicesList: [] })
const updateBundleName = (state, { bundleName }) => state.merge({ bundleName })
const updateSelectedDevicesList = (state, { selectedDevices }) => state.merge({ selectedDevicesList: selectedDevices })
const updateScheduleType = (state, { scheduleType }) => state.merge({ scheduleType, scheduleTimezone: null, scheduleFromDatetime: null, scheduleToDatetime: null })
const updateScheduleZoneAndDatetime = (state, { timezone, datetime, scheduleFromDate, scheduleFromTime }) => state.merge({ scheduleTimezone: timezone, scheduleFromDatetime: datetime, scheduleFromDate, scheduleFromTime })
const updateScheduleZoneAndToDatetime = (state, { timezone, datetime, scheduleToDate, scheduleToTime }) => state.merge({ scheduleTimezone: timezone, scheduleToDatetime: datetime, scheduleToDate, scheduleToTime })
const loadingSites = state => state.merge({ loading: true, sitesList: [] })
const updateSitesList = (state, { sitesList }) => state.merge({ sitesList: sitesList, loading: false })
const updateSitesFailure = state => state.merge({ loading: false, sitesList: [] })
const updateDeviceFilterType = (state, { deviceFilterType }) => state.merge({ deviceFilterType: deviceFilterType })
const updateSelectedSiteId = (state, { selectedSiteId }) => state.merge({ selectedSiteId: selectedSiteId })
const updateCreateBundleFilter = (state, { createBundleFilter }) => state.merge({ createBundleFilter: createBundleFilter })
const updateLicenseInstallerState = (state, { selectedBundle }) => {
  const { bundleName, selectedSoftwares, bundleDevices, selectedDevicesList, id } = selectedBundle
  return state.merge({ id, bundleName, selectedSoftwares, bundleDevices, selectedDevicesList })
}
const reset = () => INITIAL_STATE

export const licenseInstallerReducer = createReducer(INITIAL_STATE, {
  [LicenseInstallerTypes.UPDATE_SELECTED_SOFTWARES]: selectedSoftwares,
  [LicenseInstallerTypes.GET_DEVICES]: loadingDevices,
  [LicenseInstallerTypes.UPDATE_DEVICES]: updateDevicesList,
  [LicenseInstallerTypes.UPDATE_DEVICES_FAILURE]: updateDevicesFailure,
  [LicenseInstallerTypes.UPDATE_BUNDLE_NAME]: updateBundleName,
  [LicenseInstallerTypes.UPDATE_SELECTED_DEVICES]: updateSelectedDevicesList,
  [LicenseInstallerTypes.UPDATE_SCHEDULE_TYPE]: updateScheduleType,
  [LicenseInstallerTypes.UPDATE_SCHEDULE_ZONE_AND_DATETIME]: updateScheduleZoneAndDatetime,
  [LicenseInstallerTypes.UPDATE_SCHEDULE_ZONE_AND_TO_DATETIME]: updateScheduleZoneAndToDatetime,
  [LicenseInstallerTypes.UPDATE_LICENSE_INSTALLER_STATE]: updateLicenseInstallerState,
  [LicenseInstallerTypes.SAVE_BUNDLE]: loading,
  [LicenseInstallerTypes.SAVE_BUNDLE_COMPLETE]: saveBundleComplete,
  [LicenseInstallerTypes.RESET]: reset,
  [LicenseInstallerTypes.BACK]: back,  
  [LicenseInstallerTypes.GET_SITES]: loadingSites,
  [LicenseInstallerTypes.UPDATE_SITES]: updateSitesList,
  [LicenseInstallerTypes.UPDATE_SITES_FAILURE]: updateSitesFailure,
  [LicenseInstallerTypes.UPDATE_DEVICE_FILTER_TYPE]: updateDeviceFilterType,
  [LicenseInstallerTypes.UPDATE_SELECTED_SITE_ID]: updateSelectedSiteId,
  [LicenseInstallerTypes.UPDATE_CREATE_BUNDLE_FILTER]: updateCreateBundleFilter
})
